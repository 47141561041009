<template>
  <div class="modal modal_wrap">
    <div class="modal_popup">
      <span class="modal__close" @click="close">
         <svg-icon name="times"/>
        </span>
      <form class="form" v-on:submit.prevent>
        <fieldset>
          <legend class="form__title">
            <h2 class="titlePage titlePage_textLeft">
              <svg-icon name="filter" class="text_green"/>
              Filter Orders
            </h2>
          </legend>
        </fieldset>
        <label class="form__label">
          <span class="form__labelTitle">Search query</span>
          <input type="text"
                 class="form__input"
                 name="query"
                 v-model="query"
          >
        </label>
        <div class="tw-flex tw-space-x-4 tw-mt-2">
          <label class="form__label form__label_row">
            <input type="radio" class="form__radio"
                   hidden
                   value="quotesEstimatesAndDrafts"
                   :checked="selectedFilterType === 'quotesEstimatesAndDrafts'"
                   @change="setFilterTypeToQuotesEstimatesAndDrafts"
            >
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">Quotes, Estimates and Drafts</span>
          </label>

          <label class="form__label form__label_row">
            <input type="radio" class="form__radio"
                   hidden
                   value="orders"
                   :checked="selectedFilterType === 'orders'"
                   @change="setFilterTypeToOrders"
            >
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">Orders</span>
          </label>

          <label class="form__label form__label_row">
            <input type="radio" class="form__radio"
                   hidden
                   value="all"
                   :checked="selectedFilterType === 'all'"
                   @change="setFilterTypeToAll"

            >
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">All</span>
          </label>
        </div>
        <hr />
        <label class="form__label form__label_row"
               v-for="item in availableStatuses"
               :key="item.id">

          <input type="checkbox" hidden="hidden"
                 class="form__checkbox"
                 @click="checkAvailable(item, $event)"
                 :value="item"
                 v-model="selected">

          <span class="form__checkbox_icon"></span>
          <span class="form__labelTitle">{{item.name}}</span>
        </label>

        <button type="button" class="btn btn_transparent btn_default"
                @click.prevent="filterActions"
                :disabled="selected.length == 0"
        >
          <svg-icon name="solid/filter"/> Apply Filter
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex'

  export default {
    name: "FilterOrders",

    data() {
      return {
          query: '',
        selected: [],
      }
    },
    watch: {
      selectedFilterType: {
        immediate: true,
        handler(val, old) {
          // this.selected = this.availableStatuses // []//.filter(x => x.name !== 'All' && ['CNCL'].includes(x.status))
        }
      }
    },
    methods: {
      filterActions() {
        const selectedIds = this.selected.map(x => x.id).filter(i => i !== 0)
        const selected = this.availableStatuses.filter(x => selectedIds.includes(x.id))
        let selectedStates = selected.map(x => x.state)
        let selectedStatuses = selected.map(x => x.status)
        selectedStates = selectedStates.flatMap((st) => (st === 'EST') ? [st, 'QOT'] : [st]);
        selectedStatuses = selectedStatuses.flatMap((sts) => (sts === 'REVW' || sts === 'APPR' || sts === 'REJ') ? [sts, sts] : [sts]);

        this.$store.commit('filterOrders/selectedStatuses', selected);
        this.$store.commit('filterOrders/query', this.query);
        this.$store.commit('orderItems/filteredItems', []);
        this.$store.commit('orderItems/setSortBy', this.selectedFilterType)
        localStorage.setItem('ordersListType', this.selectedFilterType);
        this.$store.commit('orderItems/clearItems');
        this.$store.dispatch('orderItems/getOrders', {states: selectedStates, statuses: selectedStatuses, query: this.query});
        this.$store.commit('filterOrders/setOpenModal', false);

      },
      ...mapMutations('filterOrders',['setFilterTypeToOrders', 'setFilterTypeToQuotesEstimatesAndDrafts', 'setFilterTypeToAll']),
      checkAvailable(item, e) {
        if (item.name === 'All' && e.target.checked) {
          this.selected = this.availableStatuses
        }
        else if (item.name === 'All' && !e.target.checked) {
          this.selected = [];
        }
      },


      close() {
        this.$store.commit('filterOrders/setOpenModal', false);
      },

      checkOnSelected() {
        if (this.$store.state['filterOrders'].selectedStatuses.length > 0) {
          this.selected = this.$store.state['filterOrders'].selectedStatuses
        }
        // else {
        //   _.forEach(this.availableStatuses, item => {
        //     if (item.name !== "All" && item.status !== "CNCL") {
        //       this.selected.push(item.code);
        //       return this.selected;
        //     }
        //   });
        // }

      }
    },

    computed: {
      ...mapGetters({
        statuses: 'filterOrders/statuses',
      }),
      availableStatuses() {
        return this.$store.getters['filterOrders/availableStatuses']
      },
      selectedFilterType() {
        return this.$store.getters['filterOrders/selectedFilterType']
      }
    },

    created() {
      this.checkOnSelected();
    }
  }
</script>

<style scoped lang="scss">
  .form {
    &__label {
      margin-bottom: 5px;
      margin-right: 0;
      cursor: pointer;
    }
  }

  .modal__close {
    z-index: 99;
  }
</style>
